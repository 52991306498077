import React from 'react'
import Navbar from './navbar';

export default function Header() {
    return (
        <header>
          <Navbar />
        </header>
    );
};
